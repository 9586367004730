/* .iframe_video{
  width: 550px;
  height: 550px;
} */
.iframe_flex{
  display: flex;
  flex-direction: row !important;
  
}
@media (max-width:768px) {
  
  .iframe_flex{
    display: flex;
    flex-direction: column !important;
    
  }
  .video_adjust{
    margin-top: 20%;
    padding: 20px 0 0 20px !important;
   
   }
}
.video_adjust{
 margin-top: 20%;
 padding: 20px 0 0 80px;

}
.girl_name{
  display: flex;
  justify-content: center;
  color: #ffb945;
  align-items: center;
}
.girl_avatar{
  transition: all 0.5s ease-in-out 0s;
    
}
.girl_avatar:hover{
  transition: all 0.5s ease-in-out 0s;
    
  transform: translateY(-13px);

}
.man_avatar{
  transition: all 0.5s ease-in-out 0s;
    

}
.man_avatar:hover{
  transition: all 0.5s ease-in-out 0s;
    
  transform: translateY(-13px);

}
.alpha_tera_card{
  box-shadow: -5px -3px 35px 0px rgb(31 10 171 / 58%);
  padding: 20px;
}
/* .multi_blockcain_list{
  list-style: none;
} */
.multi_blockcain h5 {
  color: #ffb945 !important; 
}
.sliderLine{
  color: #ffb945 !important; 
  font-weight: 700;
}
.slider__slide{
  display: flex;
  flex-direction: column;
}
.btn-primary{
  background-image: linear-gradient(to right, #059cf9 0%, #ffb945 100%) !important;
  outline: none;
  border: none;
  transition: 0.5s ease-in-out;
}
.btn-primary:hover{
  
  transition: 0.5s ease-in-out;

  transform: translateY(-13px) !important;
    box-shadow: -1px 11px 18px -1px rgb(255 185 69);
}
.bottom_click_btn{
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner_line{
  border: 1px solid #D9D9D9;
      /* border-radius: 2px; */
      z-index:-1;
}
.bottomContent p {
    font-size: 18px;
    color: #fdd563;
    margin-bottom: 5px;
    margin-top: 30px;
}

.bottomContent span {
    color: #c0c0c0;
    font-size: 18px;
}
/* @media (max-width: 768px) {



  .bannerRow{
    display: flex;
    flex-direction: column-reverse !important;
  }
} */

@media (max-width: 575.98px) {



    .bottomContent p,
    .bottomContent span {
        font-size: 17px;
    }
}

.counter {
    display: flex;
    margin: 40px 0px 20px 0px;
    position: relative;
    justify-content: left;
}

.counter button {
    background-image: url('../images/mint.png');
    padding: 4px 15px;
    font-size: 19px;
    border-radius: 7px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    color: white;

    cursor: pointer;
    -webkit-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    height: 45px;

    @media (max-width: 575.99px) {
        padding: 4px 10px;

        height: 40px;
    }
}

@media (max-width: 575.98px) {
    .counter {
        display: block;
        width: 90%;

        justify-content: left;
        /* padding: 0px 0px; */
        margin-left: 43px;
    }
}

.inputField {
    width: 120px;
    text-align: center;
    height: 45px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: transparent;
    border: 2px solid #c68d15;
    border-radius: 7px;
    margin: 0px 15px;

    @media (max-width: 575.99px) {
        width: 126px;

        height: 40px;
    }
}

.mintButton {
    margin-left: 30px;
}

.mintButton button {
    border: 0;
    padding: 13px 40px;
    font-size: 18px;
    color: white;
    background-image: url('../images/mint.png');
    cursor: pointer;
    border-radius: 0px;
    border: 1px solid #c68d15;
    transition: 0.5s ease-in-out;
    margin-left: 0px;
    border-radius: 7px;
    border: 0;
}

@media (max-width: 575.98px) {
    .mintButton {
        text-align: center;
        margin: 30px 0;
        margin-left: 50px;
    }
}

.buttonGroup {
    display: flex;
    align-items: center;



    
}

@media (max-width: 768px) {
  .mint-now-button{
    
    margin-left: 2% !important;

    

  }
    .banner-title{
        padding-left: 30px;
       
    
      }
      .btn_para{
        padding-left: 30px;
      }
    .bottomContent{
        padding-left: 30px;


      }
      .circle_gallery{
        padding-right: 20px;
        margin-right: 20px;
      }
      .end_icons {
       
        padding-right:0px !important;
       
    }
    .alphaman_1 {
      display: flex;
      max-width: 100% !important;
      justify-content: center !important;
  }
   
}
.btn_para{
    margin-top: 3%;
}
.video_button{
    padding-top: 3% !important;
    width: 10%;
}

.blink {
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale(2);
  }
  51% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}


.gallery  {
    --s: 280px; /* control the size */
    /* padding-left: 60px !important; */
    display: grid;
    width: var(--s);
    aspect-ratio: 1;
    overflow: hidden;
    padding: calc(var(--s)/20);
    border-radius: 50%;
    position: relative;
    clip-path: circle(49.5%); /* to avoid a few glitch cause by overflow: hidden */

  }
  .gallery::after {
    content: "";
    position: absolute;
    inset: 0;
    padding: inherit;
    border-radius: inherit;
    background: repeating-conic-gradient(#059cf9  0 30deg, #ffb945 0 60deg);
    -webkit-mask:linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
  }

  .gallery > img {
    grid-area: 1/1;
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: inherit;
    transform-origin: 50% 120.7%;
  }
  .gallery::after,
  .gallery > img {
    animation: m 8s infinite cubic-bezier(.5,-0.2,.5,1.2);
  }
  .gallery > img:nth-child(2) {animation-delay: -2s}
  .gallery > img:nth-child(3) {animation-delay: -4s}
  .gallery > img:nth-child(4) {animation-delay: -6s}
  
  @keyframes m {
    0%,3%    {transform: rotate(0)}
    22%,27%  {transform: rotate(-90deg)}
    47%,52%  {transform: rotate(-180deg)}
    72%,77%  {transform: rotate(-270deg)}
    98%,100% {transform: rotate(-360deg)}
  }
  .alpha_icons{
    margin-top: 10%;
    display: flex;
    flex-direction: row;
    gap: 30px;
  }
  .eth_icon{
    transition: all 0.5s ease-in-out 0s;
  }
  .eth_icon:hover{
    transition: all 0.5s ease-in-out 0s;
    
    transform: translateY(-13px);
   
box-shadow: 2px 13px 34px 4px rgba(255,185,69,1);
background: transparent;

  }
  .end_icons{
    display: flex;
    justify-content: center;
    padding-right: 130px;
    margin-top: 5%;
  }
  .comingSoon_home{
    display: flex;
    flex-direction: column !important;
    gap: 50px !important; 
    list-style: none;
    margin-top: 2%;
    margin-bottom: 5%;
  }
  .mint-now-button{
    background-image: -webkit-linear-gradient(left, #059cf9 0%, #ffb945 100%) !important;
    background-image: linear-gradient(to right, #059cf9 0%, #ffb945 100%) !important;
    transition: all 0.5s ease-in-out 0s;
    margin-left: -3% !important;

    

  }

  .mint-now-button:hover {
    transition: all 0.5s ease-in-out 0s;
    transform: translateY(-13px) !important ;
    -webkit-box-shadow: -1px 11px 18px -1px rgba(255,185,69,1);
-moz-box-shadow: -1px 11px 18px -1px rgba(255,185,69,1);
box-shadow: -1px 11px 18px -1px rgba(255,185,69,1);
}
.our_partners{
 
  margin-top: 5% !important;
}
@media (max-width:768px) {
  .our_partners{
    padding-left: 24px !important;
    margin-top: 5px !important;
  }

  .alphagirl_img{
    max-width: 100% !important;
   
  }
  .home_video_btn{
    padding-left: 25px !important;
  }
  .mint-now-button{
   
  
    margin-left: 2px !important;

    

  }
    .home_btn{
        margin-bottom: 2% !important;
        margin-top: 5%;
    }
    .opensea_img{
     padding: 20px 40px 30px 30px !important;
    }
    .our_gif{
      display: flex;
      flex-direction: column-reverse !important;
      gap: 30px;
    }
    .alphaman_img{
      display: flex;
      justify-content: center !important;
      align-items: center !important;
      max-width: 100% !important;
      height: auto;
      border-radius: 10px !important;
     
    }
    .girl_row_1{
     display: flex;
     flex-direction: column-reverse;
    
    }
    .alphagirl_{
      display: flex;
      justify-content: center !important;
    }
    .alphagirl_img1{
      display: flex;

      justify-content: center !important;
      border-radius: 20px;
    }




     .comingSoon_home{
        
        margin-top: 5%;
        padding-left: 15px;
        padding-bottom: 15%;
        display: flex;
        flex-direction: column !important;
        row-gap: 20px !important;
      }
      .card-item{
        padding-left: 34px;

      }
    
}
.man_girl{
  display: flex;
  flex-direction: row-reverse;
  padding: 23px;
}
/* .girl{
  -webkit-transform: rotate(18deg);
  transform: rotate(-10deg);
}
 .man{
  -webkit-transform: rotate(18deg);
  transform: rotate(18deg);
}  */


.man_btn{
  margin-top: 5% !important;
  margin-left: -3% !important;
}



.opensea_img{
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s ease-in-out;
}
.opensea_para{
  padding-top: 20px;
}
.opensea_gif{
  border-radius: 30px;

 
}
.opensea_list_item{
  padding: 15px;
}
.our_gif{
  margin-top: 5% !important;
}
.alpha_man{
  margin-top: 5%;
}
.alphaman_img{
  display: flex;
  justify-content: end !important;
  max-width: 60% !important;
  border-radius: 10px !important;
 
}
.alphaman_{
  display: flex;
  justify-content: end !important;
}



.opensea_row{
  margin-top: 5% !important;
  
}

.girl_row{
  margin-top: 5% !important ;
}
.alphagirl_img{
  max-width: 50% !important;
  display: flex;
  justify-content: start;
  border-radius: 10px;
}
.alpha_footer{
  position: fixed;
}
.alphaman_1{
  display: flex;
  justify-content: end;
}
.girl_row_1{
  margin-top: 5% !important;
}


.sm-icons {
 
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;


}
.sm-icons a img {
    animation: myAnim 2s ease 0s infinite normal forwards;
}
.sm-icons img {
  animation: 2s ease 0s infinite normal forwards running myAnim;
}

.new_man_img{
  border-radius: 20px;
}







