/** @format */

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;1,500&display=swap");
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
body {
  /* overflow-y: hidden; */
  margin: 0;
  padding: 0;
  color: #ffffff;
  font-family: "DM Sans", sans-serif;
  /* background-color: #0b0c22; */
  background: rgb(2,0,36);
background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(42,102,168,1) 68%, rgba(0,212,255,1) 100%);
}
header {
  min-height: 80px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
}
main {
  margin: 0;
  padding: 0;
}
h1, h2, h3 , h4, h5 {
  color: white;
}
p{
  color: white;
}
li{
  color: white;
}


@media (max-width: 450px) {
  h2 {
    font-size: 30px;
  }
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1320px;
  }
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.col-md-6 {
  /* -ms-flex: 0 0 50%; */
  /* flex: 0 0 50%; */
  /* max-width: 50%; */
  padding-right: 30px;
  padding-left: 40px;
}
.col-md-3 {
  /* -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; */
  padding-right: 15px;
  padding-left: 15px;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}
/* .col-md-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  padding-right: 15px;
  padding-left: 15px;
} */
.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
/* .col-md-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
} */
.col-md-10 {
  -ms-flex: 0 0 60%;
  flex: 0 0 60%;
  max-width: 60%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-md-7 {
  flex: 0 0 auto;
  width: 58.3333333333%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-md-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
  padding-right: 15px;
  padding-left: 15px;
 
}
.accordion {
  border-radius: 5px;
}
/* .accordion .accordion-item:first-child .title {
} */
/* .accordion .accordion-item:last-child .title {
} */
.accordion .accordion-item .title {
  color: white;
  font-family: "Jost", sans-serif;
  font-weight: 500;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  font-size: 24px;
  line-height: 41px;
  padding: 1.5rem 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 0;
}
.accordion .accordion-item .title:after {
  content: url("https://api.iconify.design/ant-design/plus-outlined.svg?color=white&width=30&height=30");
  width: 30px;
  height: 30px;
  color: #777;
  float: right;
  margin-left: 5px;
}
.accordion .accordion-item .panel {
  font-family: "Jost", sans-serif;
  max-height: 0;
  -webkit-transition: max-height 0.5s ease-out;
  -o-transition: max-height 0.15s ease-out;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
}
.accordion .accordion-item.active .title {
  background-color: transparent;
  color: #fb6468;
}
.accordion .accordion-item.active .title:after {
  content: url("https://api.iconify.design/akar-icons/minus.svg?color=red&width=30&height=30");
}
.accordion .accordion-item.active .panel {
  max-height: 700px;
  transition: max-height 0.5s ease-in-out;
}
.panel p {
  padding: 0px 31px;
  font-size: 18px;
  line-height: 31px;
  margin-bottom: 20px;
  color: #c0c0c0;
}
.listItem li {
  margin-bottom: 20px;
  color: #c0c0c0;
}
