.alpha_vision{
    background-color: #2c6db3;
    margin-top: 5%;
  }
.opensea_img{
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 1s ease-in-out;
}
.opensea_img :hover{
   
    transform: translateY(-13px);
    transition: 1s ease-in-out;

}

.opensea_para{
    padding-top: 20px;
}
.opensea_gif{
    border-radius: 30px;

   
}
.opensea_list_item{
    padding: 15px;
 

}
.our_gif{
    margin-top: 5%;
}
.alpha_man{
    margin-top: 5%;
}
.alphaman_img{
    display: flex;
    justify-content: end;
}


.comingSoon_home{
    display: flex;
    flex-direction: row;
    gap: 20px;
    list-style: none;
    margin-top: 2%;
  }
  .mint-now-button{
    background-image: -webkit-linear-gradient(left, #059cf9 0%, #ffb945 100%) !important;
    background-image: linear-gradient(to right, #059cf9 0%, #ffb945 100%) !important;
    transition: all 0.5s ease-in-out 0s;
    

  }

  .mint-now-button:hover {
    transition: all 0.5s ease-in-out 0s;
    transform: translateY(-13px) !important ;
    -webkit-box-shadow: -1px 11px 18px -1px rgba(255,185,69,1);
-moz-box-shadow: -1px 11px 18px -1px rgba(255,185,69,1);
box-shadow: -1px 11px 18px -1px rgba(255,185,69,1);
}
@media (max-width:768px) {
    .home_btn{
        margin-bottom: 2% !important;
        margin-top: 5%;
    }
     .comingSoon_home{
        
        margin-top: 5%;
        padding-left: 15px;
        padding-bottom: 15%;
        display: flex;
        flex-direction: column !important;
        row-gap: 20px !important;
      }
    }
    .alpha_vision{
        padding-top: 30px;
        padding-bottom: 40px;
    }